<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Akt Sverkalar ruyhati</h3>
              </div>
              <div class="card-button">
                <create />
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :loading="isLoading"
                :items="getAktSverka"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="7"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCounteragentsCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
import create from './create'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action, create },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Mijoz nomi',
          value: 'client_name'
        },
        {
          text: 'Hisobot sanasi',
          value: 'report_date'
        },
        {
          text: 'Hisobot boshi',
          value: 'report_start_date'
        },
        {
          text: 'Hisobot oxiri',
          value: 'report_end_date'
        },
        {
          text: 'BTS debet saldo boshida',
          value: 'debet_start_saldo_company'
        },
        {
          text: 'BTS kredit saldo boshida',
          value: 'credit_start_saldo_company'
        },
        {
          text: 'Mijoz debet saldo boshida',
          value: 'debet_start_saldo_client'
        },
        {
          text: 'Mijoz kredit saldo boshida',
          value: 'credit_start_saldo_client'
        },
        {
          text: 'BTS debet saldo oxirida',
          value: 'debet_end_saldo_company'
        },
        {
          text: 'BTS kredit saldo oxiri',
          value: 'credit_end_saldo_company'
        },
        {
          text: 'Mijoz debet saldo oxiri',
          value: 'debet_end_saldo_client'
        },
        {
          text: 'Mijoz kredit saldo oxiri',
          value: 'credit_end_saldo_client'
        },
        {
          text: 'Kontrakt raqam',
          value: 'contract_number'
        },
        {
          text: 'Yaratilgan sana',
          value: 'created_at'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('CounteragentsContractAktSverkaPag', value)
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getAktSverka() {
      const data = this.$store.getters.kontragentContractAktSverka.results
      return data
    },
    getCounteragentsCount() {
      return this.$store.getters.kontragentContractAktSverka
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragentlar' },
      { title: 'Akt sverka' }
    ])
  }
}
</script>
