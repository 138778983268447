<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" small v-bind="attrs" v-on="on">
          Akt Sverka +
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">Akt sverka +</v-card-title>
        <div class="p-5">
          <v-autocomplete
            dense
            :items="contagetnList"
            item-text="name"
            item-value="id"
            @change="kontragentChange(countragent)"
            label="Kontragent nomi"
            outlined
            v-model="countragent"
          ></v-autocomplete>

          <div v-show="KontragentContract">
            <v-select
              v-model="value"
              item-value="id"
              label="Kontragent Kontrakt"
              item-text="reg_number"
              :items="getKontagentContract"
              outlined
              dense
            ></v-select>
          </div>

          <v-dialog
            ref="oper_date_dialog"
            v-model="oper_date_modal"
            :return-value.sync="oper_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Bugungi sana"
                :value="oper_date | dateFilter"
                append-icon="event"
                readonly
                dense
                outlined
                icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="oper_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="oper_date_modal = false"
                >Yopish</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.oper_date_dialog.save(oper_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="report_start_date_dialog"
            v-model="report_start_date_modal"
            :return-value.sync="report_start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Sanadan"
                :value="report_start_date | dateFilter"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="report_start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="report_start_date_modal = false"
                >Yopish</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.report_start_date_dialog.save(report_start_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="report_end_date_dialog"
            v-model="report_end_date_modal"
            :return-value.sync="report_end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Sanagacha"
                :value="report_end_date | dateFilter"
                append-icon="event"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="report_end_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="report_end_date_modal = false"
                >Yopish</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.report_end_date_dialog.save(report_end_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">Bekor qilish</v-btn>
          <v-btn color="primary" @click="submit(), (dialog = false)"
            >Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      KontragentContract: false,
      value: '',

      countragent: '',
      oper_date: '',
      report_end_date: '',
      report_start_date: '',
      report_start_date_dialog: false,
      report_end_date_dialog: false,
      oper_date_dialog: false,
      oper_date_modal: false,
      report_start_date_modal: false,
      report_end_date_modal: false,
      dialog: false
    }
  },
  created() {
    this.$store.dispatch('getKontragentsContractsListWithoutPg')
  },
  computed: {
    contagetnList() {
      const data = this.$store.state.requests.kontragetnsWithoutPg
      return data
    },
    getKontagentContract() {
      return this.$store.state.requests.counteragentContracts
    }
  },
  methods: {
    kontragentChange(value) {
      if (value !== null) {
        this.$store.dispatch('counteragentContracts', value)
        this.KontragentContract = true
      }
    },
    submit() {
      const data = {
        contract: this.value,
        oper_date: this.oper_date,
        report_start_date: this.report_start_date,
        report_end_date: this.report_end_date
      }
      this.$store.dispatch('KontragentContractAktSverkaCreate', data)
      this.value = ''
      this.oper_date = ''
      this.countragent = ''
      this.report_start_date = ''
      this.report_end_date = ''
    }
  }
}
</script>

<style></style>
